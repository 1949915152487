<template>
  <v-form v-model="valid" :disabled="loading" @submit.prevent="onSubmit">
    <v-card-text>
      <v-text-field
        v-model="componentGroupData.description"
        class="mb-2"
        :label="$t('assets.structure.componentGroups.field.description') + ' *'"
        :rules="[v => !!v || $t('validation.required')]"
      />

      <v-text-field
        v-model="componentGroupData.key"
        class="mb-2"
        :label="$t('assets.structure.componentGroups.field.key')"
      />

      <v-autocomplete
        v-model="componentGroupData.organization"
        :items="customers"
        class="mb-2"
        :label="$t('assets.structure.componentGroups.field.organization')"
        item-title="description"
        item-value="_id"
        clearable
      ></v-autocomplete>

      <v-select
        v-model="componentGroupData.domain"
        :label="$t('admin.domains.title') + ' *'"
        :items="[DomainType.CIVIL, DomainType.PUMPING_STATIONS]"
        :rules="[v => !!v || $t('validation.required')]"
        :disabled="!!domainBySelectedOrganization || !!componentGroup || !!domainType"
      >
        <template #selection="{ item }">
          <domain-type-badge :type="item.value" />
        </template>
        <template #item="{ item, props }">
          <v-list-item v-bind="props" :title="undefined">
            <domain-type-badge :type="item.value" />
          </v-list-item>
        </template>
      </v-select>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :loading="loading">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import { DomainType } from "~~/stores/organization.store"

const emit = defineEmits<{
  (e: "submit", componentGroup: ComponentGroup): void
  (e: "cancel"): void
}>()

const props = defineProps<{
  componentGroup?: ComponentGroup
  organizationId?: string
  domainType?: DomainType
}>()
const { componentGroup, organizationId, domainType } = toRefs(props)

const $i18n = useI18n()
const mainStore = useMainStore()
const componentStore = useComponentStore()
const organizationStore = useOrganizationStore()

useLazyAsyncData("organizations", () => organizationStore.getAllOrganizations({  pagination: false, query: JSON.stringify({ domain: domainType?.value }), sort: JSON.stringify({ description: 1 }) }), { default: () => [] as Array<Organization> })
const { customers } = storeToRefs(organizationStore)

const loading = ref(false)

const valid = ref<boolean | null>()

const componentGroupData = ref<Partial<ComponentGroup>>({
  organization: organizationId?.value ?? componentGroup?.value?.organization,
  description: componentGroup?.value?.description,
  key: componentGroup?.value?.key,
  domain: domainType?.value ?? undefined,
})

const domainBySelectedOrganization = computed(() => {
  const organization = customers.value.find(organization => organization._id === componentGroupData.value.organization)
  return organization?.domain
})

watch(() => componentGroupData.value.organization, () => {
  if (domainBySelectedOrganization.value) {
    componentGroupData.value.domain = domainBySelectedOrganization.value
  }
})

const onSubmit = async () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    loading.value = true

    try {
      const componentGroupResponse = componentGroup?.value
        ? await componentStore.updateGroup(componentGroup.value._id, componentGroupData.value)
        : await componentStore.createGroup(componentGroupData.value)

      emit("submit", componentGroupResponse)
    } finally {
      loading.value = false
    }
  })
}
</script>