<template>
  <v-form v-model="valid" :disabled="loading" @submit.prevent="onSubmit">
    <v-card-text>
      <v-select
        v-model="componentData.componentType"
        :items="componentTypes"
        item-value="description"
        item-title="description"
        :label="$t('assets.structure.components.field.componentType') + ' *'"
        :rules="[v => !!v || $t('validation.required')]"
      >
        <template #selection="{ item }">
          <domain-type-badge v-if="item.raw.domain" :type="item.raw.domain" :model-title="item.raw.description" />
          <span v-else>{{ item.raw.description }}</span>
        </template>

        <template #item="{ item, props: itemProps }">
          <v-list-item v-bind="itemProps" :title="undefined">
            <domain-type-badge :type="item.raw.domain" :model-title="item.title" />
          </v-list-item>
        </template>
      </v-select>

      <v-text-field
        v-model="componentData.description"
        class="mb-2"
        :label="$t('assets.structure.components.field.description') + ' *'"
        :rules="[v => !!v || $t('validation.required')]"
      />

      <v-text-field
        v-model="componentData.key"
        class="mb-2"
        :label="$t('assets.structure.components.field.key')"
      />

      <v-autocomplete
        v-model="componentData.organization"
        :items="customers"
        class="mb-2"
        :label="$t('assets.structure.components.field.organization')"
        item-title="description"
        item-value="_id"
        clearable
      />

      <v-select
        v-model="componentData.domain"
        :label="$t('admin.domains.title') + ' *'"
        :items="[DomainType.CIVIL, DomainType.PUMPING_STATIONS]"
        :rules="[v => !!v || $t('validation.required')]"
        :disabled="!!domainBySelectedValue || !!component || !!domainType"
      >
        <template #selection="{ item }">
          <domain-type-badge :type="item.raw" />
        </template>
        <template #item="{ item, props: itemProps }">
          <v-list-item v-bind="itemProps" :title="undefined">
            <domain-type-badge :type="item.raw" />
          </v-list-item>
        </template>
      </v-select>

      <v-list-item variant="tonal" tag="label" link>
        <template #prepend>
          <v-checkbox
            v-model="componentData.hasGeneralCondition"
            color="primary"
            hide-details
            class="mr-2"
          />
        </template>

        <template #title>
          {{ $t('assets.structure.components.field.hasGeneralCondition') }}
        </template>
      </v-list-item>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :loading="loading">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import { DomainType } from "~~/stores/organization.store"

const emit = defineEmits<{
  (e: "submit", component: Component): void
  (e: "cancel"): void
}>()

const props = defineProps<{
  component?: Component
  organizationId?: string
  domainType?: DomainType
}>()
const { component, organizationId, domainType } = toRefs(props)

const { data: componentTypes } = useLazyAsyncData("component-types", () => componentStore.getComponentTypes({ query: JSON.stringify({ domain: domainType?.value }) }), { default: () => [] as Array<ComponentType> })
useLazyAsyncData("organizations", () => organizationStore.getAllOrganizations({ pagination: false, query: JSON.stringify({ domain: domainType?.value }), sort: JSON.stringify({ description: 1 }) }), { default: () => [] as Array<Organization> })

const $i18n = useI18n()
const mainStore = useMainStore()
const componentStore = useComponentStore()
const organizationStore = useOrganizationStore()

const { customers } = storeToRefs(organizationStore)

const loading = ref(false)

const valid = ref<boolean | null>()

const componentData = ref<Partial<Component>>({
  organization: organizationId?.value ?? component?.value?.organization,
  description: component?.value?.description,
  key: component?.value?.key,
  hasGeneralCondition: component?.value?.hasGeneralCondition ?? false,
  componentType: component?.value?.componentType,
  domain: domainType?.value ?? undefined,
})

const domainBySelectedValue = computed(() => {
  let domain: DomainType | undefined
  domain = componentTypes.value.find(componentType => componentType.description === componentData.value.componentType)?.domain
  domain = customers.value.find(organization => organization._id === componentData.value.organization)?.domain
  return domain
})

watch(() => [componentData.value.componentType!, componentData.value.organization], () => {
  if (domainBySelectedValue.value) {
    componentData.value.domain = domainBySelectedValue.value
  }
})

const onSubmit = async () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    loading.value = true

    try {
      const componentResponse = component?.value
        ? await componentStore.updateComponent(component.value._id, componentData.value)
        : await componentStore.createComponent(componentData.value)

      emit("submit", componentResponse)
    } finally {
      loading.value = false
    }
  })
}
</script>